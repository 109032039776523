.custom-checkbox {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.1;
  cursor: pointer;

  @media @tablet {
    font-size: 16px;
    line-height: 1.2;
  }

  &__indicator {
    display: block;
    width: 11px;
    height: 11px;
    margin-right: 20px;
    border: 2px solid @color-main;
    transition: background-color 0.15s linear;
  }

  &__checkbox:checked + &__indicator {
    background-color: @color-main;
  }
}
