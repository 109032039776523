.clarification {
  position: relative;
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: @color-secondary;

  @media @desktop {
    padding-top: 75px;
    padding-bottom: 60px;
  }

  &__container {
    @media @tablet {
      display: flex;
    }
  }

  &__left-side {
    margin-bottom: 35px;

    @media @tablet {
      width: 50%;
      padding-right: 30px;
      margin-bottom: 0;
    }
  }

  &__right-side {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 220px;

    @media @tablet {
      position: static;
      display: block;
      min-height: auto;
      width: 50%;
      z-index: 5;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -20px;
      right: -20px;
      bottom: -35px;
      background-image: url("../image/clarification-background.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;
      z-index: 0;

      @media @tablet {
        left: 50%;
        right: 0;
        bottom: 0;
        z-index: -1;
      }
    }
  }

  &__slogan {
    #default > .reset-indents();

    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 700;
    color: black;
    text-transform: uppercase;
    letter-spacing: 0.9px;
    text-align: center;

    @media @tablet {
      margin-bottom: 25px;
      font-size: 24px;
    }

    @media @desktop {
      margin-bottom: 30px;
      font-size: 36px;
      line-height: 48px;
      font-weight: 600;
      text-align: left;
    }

    &_view_right-side {
      margin-top: auto;
      font-size: 18px;
      line-height: 1.1;
      margin-bottom: 15px;

      @media @tablet {
        margin-bottom: 30px;
        font-size: 30px;
        line-height: 40px;
      }
    }
  }

  &__sub-slogan {
    #default > .reset-indents();

    margin-bottom: 35px;
    font-size: 14px;
    line-height: 1.1;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.5px;

    @media @tablet {
      margin-bottom: 45px;
      font-size: 18px;
    }

    @media @desktop {
      margin-bottom: 50px;
      font-size: 20px;
      line-height: 1.2;
      text-align: left;
    }
  }

  &__notification {
    font-size: 14px;
    line-height: 1.1;
    font-weight: 700;
    color: black;

    @media @desktop {
      font-size: 18px;
    }
  }

  &__form {
    padding-left: 30px;
    padding-right: 30px;

    @media @tablet {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__form-field {
    #default > .reset-indents();

    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    &_view_notification {
      margin-top: 30px;
      margin-bottom: 35px;
      text-align: center;

      @media @tablet {
        margin-top: 50px;
        margin-bottom: 45px;
        text-align: left;
      }

      @media @desktop {
        margin-top: 65px;
        margin-bottom: 70px;
      }
    }

    &_view_submit {
      text-align: center;
    }
  }

  &__right-content {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;

    @media @tablet {
      position: absolute;
      top: 0;
      left: 50%;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 60px;
    }
  }
}
