.trust {
  position: relative;
  z-index: 5;
  padding-top: 45px;
  padding-bottom: 40px;
  background-color: @color-secondary;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-image: url("../image/trust-background.jpg");

  @media @tablet {
    padding-top: 60px;
    padding-bottom: 50px;
  }

  @media @desktop {
    padding-top: 85px;
    padding-bottom: 50px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.7);
  }

  &__slogan {
    #default > .reset-indents();

    margin-bottom: 40px;
    font-size: 18px;
    line-height: 1.1;
    text-align: center;
    letter-spacing: 0.9px;
    color: white;
    text-transform: uppercase;

    @media @tablet {
      font-size: 24px;
    }

    @media @desktop {
      margin-bottom: 60px;
      font-size: 36px;
      line-height: 48px;
    }
  }

  &__list {
    #default > .reset-indents();
    margin-bottom: 30px;
    list-style: none;

    @media @tablet {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 55px;
    }

    @media @desktop {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 70px;
    }
  }

  &__item {
    @media @phone-only {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media @tablet-only {
      .column-mixin(3, 20px);
    }

    @media @desktop {
      .column-mixin(3, 55px);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    color: white;

    @media @phone-only {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media @tablet {
      margin-bottom: 35px;
    }

    @media @desktop {
      margin-bottom: 55px;
    }
  }

  &__number {
    display: block;
    margin-right: 10px;
    font-size: 40px;
    line-height: 1.2;
    color: @color-main;
  }

  &__item-slogan,
  &__title {
    display: block;
    font-size: 12px;
    line-height: 1.2;

    @media @tablet {
      font-size: 16px;
    }

    @media @desktop {
      font-size: 20px;
    }
  }

  &__item-slogan {
    text-transform: uppercase;
  }

  &__footer {
    text-align: center;
  }
}
