.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: none;
  width: 320px;
  box-sizing: border-box;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  background-color: @color-main_contrast;
  border-radius: 7px;
  transform: translate(-50%, -50%);

  @media @desktop {
    width: 480px;
    padding: 40px 50px;
  }
}
