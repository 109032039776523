#default {
  .reset-indents {
    margin: 0;
    padding: 0;
  }

  .button {
    display: inline-flex;
    font-family: @font-main;
    font-size: 14px;
    line-height: 20px;
    outline: none;
    text-decoration: none;
    cursor: pointer;
  }
}
