.main {
  flex-grow: 1;

  &_view_inner_page {
    display: flex;
    flex-direction: column;
  }

  &_view_inner_page .catalog,
  &_view_inner_page .useful,
  &_view_inner_page .reviews {
    padding-top: 180px;
    flex-grow: 1;
  }

  &_view_inner_page .map {
    flex-grow: 1;
  }
}
