.form {
  &__container {
    display: flex;
    flex-direction: column;

    @media @tablet {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__field {
    #default > .reset-indents();
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    @media @tablet {
      margin-right: 30px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    &_view_submit {
      text-align: center;

      @media @desktop {
        text-align: right;
      }
    }
  }

  &_view_four &__field {
    @media @tablet-only {
      .column-mixin(3, 30px);
    }

    @media @desktop {
      .column-mixin(4, 30px);
    }
  }

  &_view_four &__field_view_submit {
    @media @tablet-only {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &_view_three &__field {
    @media @tablet {
      flex-grow: 1;
      margin-right: 30px;

      &:last-child {
        flex-grow: 0;
        margin-left: 80px;
        margin-right: 0;
      }
    }

    @media @desktop {
      margin-right: 50px;
    }
  }
}
