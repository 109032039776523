.shadow {
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 0;
    height: 0;
    background: rgba(1, 35, 62, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.15s ease-in, visibility 0.15s ease-in;
  }

  &_state_open {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &::before {
      width: 100%;
      height: 100%;
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s ease-out, visibility 0.15s ease-out;
    }
  }
}
