.button {
  #default > .button();
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  font-size: 10px;
  line-height: 12px;
  color: @button-main-text;
  background: @button-main;
  border: 2px solid @button-main;
  transition: background-color 0.15s linear;
  text-transform: uppercase;
  text-align: center;

  @media @tablet {
    min-width: 170px;
    padding: 6px 25px;
    font-size: 14px;
    line-height: 16px;
  }

  &:hover,
  &:focus {
    background-color: @button-main_hover;
    color: @button-main-text_hover;
    transition: background-color 0.15s linear;
  }
}
