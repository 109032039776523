.container {
  width: auto;
  margin-right: 0;
  margin-left: 0;
  padding-right: @container-indets-phone;
  padding-left: @container-indets-phone;

  @media @desktop {
    width: 1140px;
    margin-right: auto;
    margin-left: auto;
    padding-right: @container-indets-desktop;
    padding-left: @container-indets-desktop;
  }

  @media @desktop-medium {
    padding-right: @container-indets-desktop-medium;
    padding-left: @container-indets-desktop-medium;
  }

  @media @desktop-large {
    padding-right: @container-indets-desktop-medium;
    padding-left: @container-indets-desktop-medium;
  }
}
