/** Fonts **/
.include-font(@file, @family, @weight: normal, @style: normal) {
  @font-face {
    font-weight: @weight;
    font-family: @family;
    font-style: @style;
    src: url('@{file}.woff2') format('woff2'), url('@{file}.woff') format('woff');
    font-display: swap;
  }
}

/** Media Queries **/
@tablet: ~'only screen and (min-width: @{tablet-size})';
@desktop: ~'only screen and (min-width: @{desktop-size})';
@desktop-medium: ~'only screen and (min-width: @{desktop-medium-size})';
@desktop-large: ~'only screen and (min-width: @{desktop-large-size})';

@phone-small-only: ~'only screen and (max-width: @{to-phone-small-size})';
@phone-only: ~'only screen and (max-width: @{to-tablet-size})';
@tablet-only: ~'(min-width: @{tablet-size}) and (max-width: @{to-desktop-size})';
@desktop-only: ~'(min-width: @{desktop-size}) and (max-width: @{to-desktop-medium-size})';
@desktop-medium-only: ~'(min-width: @{desktop-medium-size}) and (max-width: @{to-desktop-large-size})';

@desktop-max: ~'only screen and (max-width: @{to-desktop-size})';

/** Mixins tile **/
.column-mixin(@count, @margin) when (@count = 1) {
  width: 100%;
}

.column-mixin(@count, @margin) when (@count > 1) {
  width: ~'calc(' (100% / @count) ~' - ' ((@margin * (@count - 1)) / @count) ~')';
}

.column-mixin(@count, @margin) {
  margin-top: @margin;
  margin-right: @margin;

  &:nth-child(@{count}n) {
    margin-right: 0;
  }

  &:nth-child(-n + @{count}) {
    margin-top: 0;
  }
}

.placeholder(@color) {
  &::-webkit-input-placeholder {
    color: @color;
  }

  &:-moz-placeholder {
    color: @color;
  }

  &::-moz-placeholder {
    color: @color;
  }

  &:-ms-input-placeholder {
    color: @color;
  }
}

.scrollbar() {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: @blue_lighten;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @blue;
    border-radius: 5px;
  }
}

.string-cat(@lines, @lineHeight) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @lines; /* number of lines to show */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: @lineHeight; /* fallback */
  max-height: @lineHeight * @lines; /* fallback */
}
