.product {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 300px;
  background-color: @color-secondary;

  @media @tablet {
    padding-top: 180px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    background-color: @color-main_contrast;

    @media @desktop {
      width: 50%;
    }
  }

  &__image-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 60%;
    z-index: 5;
    width: 100%;

    @media @tablet {
      bottom: 30%;
    }

    @media @desktop {
      width: 50%;
      bottom: 30%;
    }
  }

  &__image {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__container {
    display: flex;
    flex-direction: column;
    z-index: 10;
    flex-grow: 1;

    @media @desktop {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__card {
    width: 100%;

    @media @tablet {
      width: 50%;
      margin-left: auto;
    }

    @media @tablet-only {
      margin-right: -20px;
    }

    @media @desktop {
      width: 725px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__footer-container {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;

    @media @desktop {
      margin-top: -90px;
    }
  }

  &__consultant {
    margin-right: 10px;
    background-color: @color-main_contrast;

    @media @desktop {
      width: 500px;
      margin-right: 20px;
    }
  }

  &__video-link {
    margin-left: auto;
    margin-right: -20px;

    @media @desktop {
      margin-right: 0;
    }
  }
}
