.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding-top: 30px;
  padding-bottom: 30px;
  background-image: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0) 100%);

  &_no-gradient {
    background-image: none;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__logo,
  &__nav {
    margin-right: 20px;

    @media @desktop {
      margin-right: 80px;
    }
  }

  &__nav {
    @media @desktop-max {
      position: absolute;
      top: 100%;
      right: 0;
      background-color: white;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0.15s linear, opacity 0.15s linear;
    }

    @media @desktop {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__nav&__nav_state_open {
    opacity: 1;
    visibility: visible;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    @media @tablet {
      flex-direction: row;
      margin-left: auto;
      margin-right: 30px;
    }

    @media @desktop {
      flex-direction: column;
      margin-right: 0;
      margin-left: 80px;
    }
  }

  &__phone {
    margin-bottom: 10px;
    font-size: 14px;
    color: @color-text_contrast;
    text-decoration: none;
    transition: color 0.15s linear;

    @media @tablet {
      margin-right: 10px;
      margin-bottom: 0;
      font-size: 20px;
    }

    @media @desktop {
      margin-right: 0;
      margin-bottom: 10px;
    }

    &:hover {
      color: @color-main;
      text-decoration: underline;
    }
  }

  &__button-mobile {
    align-self: flex-end;

    @media @tablet {
      align-self: center;
    }

    @media @desktop {
      display: none;
    }
  }

  &__button {
    position: relative;
    min-width: 27px;
    min-height: 20px;
    margin: 0;
    padding: 2px;
    background-color: initial;
    border: none;
    cursor: pointer;

    &::after,
    &::before {
      content: "";
      display: block;
      width: 27px;
      height: 3px;
      background-color: @color-text;
      transition: transform 0.15s linear;
    }
  }

  &__button-mobile-line {
    width: 27px;
    height: 3px;
    margin-top: 6px;
    margin-bottom: 6px;
    background-color: @color-text;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s linear, visibility 0.15s linear;
  }
}
