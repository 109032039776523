.map {
  width: 100%;
  height: 600px;

  &__container {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-image: url("../image/map.jpg");
  }
}
