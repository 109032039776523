.logo {
  &__link {
    display: block;
  }

  &__image {
    display: block;
    width: 55px;
    height: 45px;

    @media @tablet {
      width: 94px;
      height: 78px;
    }
  }
}
