.reviews {
  &__container {
    padding-bottom: 35px;
  }

  &__slogan {
    #default > .reset-indents();

    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.1;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    text-align: center;
    color: black;

    @media @tablet {
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 1.2;
    }

    @media @desktop {
      font-size: 36px;
      line-height: 48px;
    }

    &_view_footer {
      margin-bottom: 10px;
      letter-spacing: 0;

      @media @desktop {
        margin-bottom: 30px;
      }
    }
  }

  &__sub-slogan {
    #default > .reset-indents();

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.5px;
    color: #000000;

    @media @tablet {
      margin-bottom: 45px;
      font-size: 18px;
    }

    @media @desktop {
      margin-bottom: 60px;
      font-size: 20px;
      line-height: 28px;
    }

    &::after,
    &::before {
      content: "";
      display: block;
      width: 135px;
      height: 2px;
      background-color: @color-main;
    }
  }

  &__sub-slogan-text {
    margin-right: 10px;
    margin-left: 10px;

    @media @tablet {
      margin-right: 20px;
      margin-left: 20px;
    }

    @media @desktop {
      margin-right: 30px;
      margin-left: 30px;
    }
  }

  &__list {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 2px solid @color-main;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image-container,
  &__image {
    width: 60px;
    height: 60px;

    @media @tablet {
      width: 85px;
      height: 85px;
    }
  }

  &__item:first-child &__image-container,
  &__item:first-child &__image {
    width: 80px;
    height: 80px;

    @media @tablet {
      width: 110px;
      height: 110px;
    }
  }

  &__image-container {
    flex-shrink: 0;
    margin-right: 30px;

    @media @tablet {
      margin-right: 65px;
    }
  }

  &__item:first-child &__image-container {
    margin-right: 11px;

    @media @tablet {
      margin-right: 45px;
    }
  }

  &__image {
    object-fit: cover;
  }

  &__text-container {
    flex-grow: 1;

    p {
      font-weight: 500;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  &__form-container {
    display: flex;
    margin-bottom: 20px;

    @media @tablet {
      margin-bottom: 30px;
    }
  }

  &__textarea {
    flex-grow: 1;
  }

  &__form-actions {
    display: flex;

    button {
      margin-left: auto;
    }
  }
}
