.socials {
  #default > .reset-indents();
  list-style: none;

  @media @tablet {
    display: flex;
  }

  &__item {
    @media @phone-only {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media @tablet {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__link {
    display: block;
  }

  &__icon {
    width: 30px;
    height: 30px;
    fill: @color-text;
    transition: fill 0.15s linear;

    @media @tablet {
      width: 45px;
      height: 35px;
    }
  }

  &__link:hover &__icon {
    fill: @color-main;
  }
}
