.main-footer {
  padding-top: 35px;
  padding-bottom: 30px;
  background-color: @color-secondary;
  border-top: 6px solid @color-main;

  @media @desktop {
    padding-top: 45px;
    padding-bottom: 40px;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__contacts {
    #default > .reset-indents();
    margin-right: 30px;
    list-style: none;

    @media @tablet {
      display: flex;
      flex-wrap: wrap;
      margin-right: 40px;
    }


    @media @desktop {
      flex-wrap: nowrap;
      margin-right: 110px;
    }
  }

  &__contact {
    display: flex;
    align-items: center;

    @media @phone-only {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media @tablet {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media @desktop {
      margin-right: 70px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__contact-icon {
    width: 22px;
    height: 28px;
    margin-right: 15px;
    flex-shrink: 0;
    fill: @color-main;

    @media @desktop {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
  }

  &__contact_view_email &__contact-icon {
    @media @desktop {
      width: 23px;
      height: 16px;
    }
  }

  &__contact-text {
    #default > .reset-indents();

    font-size: 12px;
    line-height: 1.2;
    color: @color-text;
    text-decoration: none;

    @media @desktop {
      font-size: 14px;
    }

    &_view_big {
      @media @desktop {
        font-size: 18px;
      }
    }

    &_view_bold {
      flex-shrink: 0;
    }
  }

  & &__socials  {
    margin-left: auto;
  }
}
