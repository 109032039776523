.video-play {
  background-color: @color-main;

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
    text-decoration: none;

    @media @tablet {
      padding: 30px 40px;
    }
  }

  &__title {
    #default > .reset-indents();

    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.1;
    color: @color-text_contrast;
    text-transform: uppercase;
    text-align: center;

    @media @tablet {
      margin-bottom: 15px;
      font-size: 20px;
    }
  }

  &__icon {
    width: 50px;
    height: 50px;
    fill: @color-text_contrast;

    @media @tablet {
      width: 68px;
      height: 68px;
    }
  }
}
