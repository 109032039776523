.video-catalog {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 180px;

  &__container {
    padding-top: 20px;
    padding-bottom: 20px;

    @media @desktop {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__slogan {
    #default > .reset-indents();

    margin-bottom: 25px;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: black;
    text-align: center;

    @media @tablet {
      margin-bottom: 30px;
      font-size: 24px;
    }

    @media @desktop {
      margin-bottom: 35px;
      font-size: 36px;
      line-height: 48px;
    }
  }

  &__title {
    #default > .reset-indents();

    margin-bottom: 35px;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;

    @media @tablet {
      width: 650px;
      margin-bottom: 55px;
      margin-right: auto;
      margin-left: auto;
      font-size: 16px;
    }

    @media @desktop {
      margin-bottom: 60px;
      font-size: 16px;
    }

    &::before {
      content: "*";
      font-size: 12px;
      line-height: 1;
      color: @color-main;
    }
  }

  &__list {
    #default > .reset-indents();
    list-style: none;

    @media @tablet {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    cursor: pointer;

    @media @phone-only {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media @tablet-only {
      .column-mixin(3, 35px);
    }

    @media @desktop {
      .column-mixin(4, 35px);
    }
  }

  &__image-container {
    position: relative;
    height: 130px;
    margin-bottom: 20px;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    fill: @color-main;
    transform: translate(-50%, -50%);
    transition: fill 0.15s linear;
  }

  &__item:hover &__icon {
    fill: @color-main_contrast;
  }

  &__image {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__list-title {
    font-size: 14px;
    line-height: 1.2;
    color: black;
    text-transform: uppercase;
    text-align: center;
    transition: color 0.15s linear;

    @media @desktop {
      font-size: 18px;
    }
  }

  &__item:hover &__list-title {
    color: @color-main;
  }
}
