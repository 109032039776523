.steps {
  padding-top: 40px;
  padding-bottom: 35px;
  background-color: @color-secondary;

  @media @tablet {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media @desktop {
    padding-top: 85px;
    padding-bottom: 50px;
  }

  &__slogan {
    #default > .reset-indents();

    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.1;
    text-transform: uppercase;
    color: black;
    text-align: center;
    letter-spacing: 0.9px;

    @media @tablet {
      width: 680px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      font-size: 24px;
    }

    @media @desktop {
      width: 1000px;
      margin-bottom: 30px;
      font-size: 36px;
      line-height: 48px;
    }
  }

  &__sub-slogan {
    #default > .reset-indents();

    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.5px;

    @media @tablet {
      margin-bottom: 60px;
      font-size: 18px;
    }

    @media @desktop {
      font-size: 20px;
      line-height: 1.2;
    }
  }

  &__list {
    #default > .reset-indents();
    list-style: none;
    margin-bottom: 25px;

    @media @tablet {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 45px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px;
    border: 4px solid white;
    transition: background-color 0.15s linear;
    cursor: pointer;

    @media @phone-only {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media @tablet-only {
      padding: 25px;
      .column-mixin(3, 20px);
    }

    @media @desktop {
      padding: 30px;
      .column-mixin(3, 30px);
    }

    &:hover {
      background-color: white;
    }
  }

  &__item-icon {
    width: 50px;
    height: 45px;
    margin-bottom: 30px;
    fill: @color-main;

    @media @tablet {
      width: 68px;
      height: 68px;
      margin-bottom: 25px;
    }

    @media @desktop {
      width: 110px;
      height: 90px;
      margin-bottom: 30px;
    }
  }

  &__item-title {
    #default > .reset-indents();

    margin-bottom: 10px;
    font-size: 10px;
    line-height: 1.1;
    color: @color-text;
    text-transform: uppercase;

    @media @tablet {
      margin-bottom: 15px;
      font-size: 14px;
    }

    @media @desktop {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 1.2;
    }
  }

  &__icon-next {
    width: 40px;
    height: 20px;
    align-self: flex-end;

    @media @tablet {
      width: 60px;
      height: 25px;
    }

    @media @desktop {
      width: 75px;
      height: 35px;
    }
  }

  &__footer {
    text-align: center;
  }
}
