ul:not([class]),
ol:not([class]) {
  #default > .reset-indents();
  margin-top: 25px;
  margin-bottom: 25px;
  list-style: none;

  @media @desktop {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  li {
    position: relative;
    margin-bottom: 20px;
    padding-left: 30px;
    font-size: 14px;
    color: @color-text;

    @media @desktop {
      font-size: 16px;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

p:not([class]) {
  #default > .reset-indents();
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;
  line-height: 14px;

  @media @tablet {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 1.3;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: @color-text;
}

h1 {
  font-size: 22px;
  line-height: 24px;
}

h2 {
  font-size: 20px;
  line-height: 22px;
}

h3 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 17px;
  line-height: 20px;
}

input,
select,
textarea {
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  padding: 13px 15px;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: @color-text;
  border: 2px solid @color-main;
  background-color: transparent;
  .placeholder(@color-text);
}

textarea {
  font-size: 10px;
  height: 115px;
  resize: none;

  @media @tablet {
    font-size: 12px;
    line-height: 15px;
  }
}
