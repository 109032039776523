.main-nav {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid @color-main;

  @media @desktop {
    padding-top: 30px;
    padding-bottom: 0;
  }

  &__list {
    #default > .reset-indents();
    list-style: none;

    @media @desktop {
      display: flex;
      padding-right: 30px;
      padding-left: 30px;
    }
  }

  &__item {
    @media @desktop {
      margin-right: 80px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__link {
    font-size: 16px;
    text-transform: uppercase;
    color: @color-text;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.15s linear;

    @media @phone-only {
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding: 15px 45px;
      font-size: 14px;
    }

    @media @tablet-only {
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding: 20px 80px;
      text-align: center;
    }

    &:hover {
      color: @color-main;
      text-decoration: underline;
    }
  }
}
