.feedback {
  &__slogan {
    #default > .reset-indents();

    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 400;
    text-align: center;
    color: black;
    text-transform: uppercase;

    @media @tablet {
      font-size: 20px;
      line-height: 1.2;
    }

    @media @desktop {
      margin-bottom: 25px;
      font-size: 30px;
      line-height: 1.2;
    }
  }

  &__content {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: @color-secondary;

    @media @tablet {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &__content .container {
    @media @phone-only {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}
