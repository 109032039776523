.first-screen {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 140px;
  min-height: 100vh;
  box-sizing: border-box;

  &__container-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__container {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__content-center {
    margin-top: auto;
    margin-bottom: auto;
  }

  &__slogan {
    #default > .reset-indents();

    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 700;
    color: @color-text;
    text-transform: uppercase;
    text-align: center;

    @media @tablet {
      margin-bottom: 30px;
      font-size: 24px;
    }

    @media @desktop {
      margin-bottom: 40px;
      font-size: 36px;
      font-weight: 600;
    }
  }

  &__sub-slogan {
    #default > .reset-indents();

    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
    color: @color-text;

    @media @tablet {
      margin-bottom: 60px;
      font-size: 18px;
    }

    @media @desktop {
      font-size: 20px;
      line-height: 48px;
    }
  }

  &__advantages {
    #default > .reset-indents();
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    list-style: none;
  }

  &__advantages-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media @phone-only {
      .column-mixin(2, 10px);
      flex-grow: 1;
    }

    @media @tablet {
      .column-mixin(3, 30px);
    }
  }

  &__advantages-icon {
    width: 37px;
    height: 37px;
    margin-bottom: 20px;
    fill: @color-main;

    @media @tablet {
      width: 50px;
      height: 50px;
      margin-bottom: 30px;
    }

    @media @desktop {
      width: 70px;
      height: 50px;
    }
  }

  &__advantages-item:last-child &__advantages-icon {
    @media @phone-only {
      width: 45px;
      height: 45px;
    }
  }

  &__advantages-title {
    #default > .reset-indents();

    font-size: 12px;
    color: @color-text;
    text-align: center;

    @media @tablet {
      font-size: 16px;
    }

    @media @desktop {
      font-size: 20px;
    }
  }

  &__slogan-form {
    margin-top: auto;
    font-size: 18px;
    font-weight: 400;
    color: @color-text;
    text-transform: uppercase;
    text-align: center;

    @media @tablet {
      margin-bottom: 30px;
      font-size: 24px;
    }

    @media @desktop {
      font-size: 30px;
    }
  }

  &__contacts {
    position: relative;
    z-index: 5;
    padding-top: 25px;
    padding-bottom: 25px;
    color: @color-text;
    background-color: @color-text_contrast;

    @media @tablet {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}
