.catalog-list {
  #default > .reset-indents();
  list-style: none;

  @media @tablet {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex-direction: column;
    min-height: 190px;

    @media @phone-only {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media @tablet {
      min-height: 250px;
    }

    @media @tablet-only {
      .column-mixin(2, 35px);
    }

    @media @desktop {
      .column-mixin(3, 30px);
    }
  }

  &__content {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    box-sizing: border-box;
    height: 100%;
    padding: 20px 30px;
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.7);
      transition: background-color 0.15s linear;
    }

    &:hover::after {
      background-color: rgba(0, 0, 0, 0.5);
    }

    &_view_tral {
      background-image: url("../image/tral.jpg");
    }

    &_view_tractor {
      background-image: url("../image/tractor.jpg");
    }

    &_view_marketing {
      background-image: url("../image/marketing.jpg");
    }

    &_view_tgs {
      background-image: url("../image/MANS.jpg");
    }

    &_view_tgs-bort {
      background-image: url("../image/Tga.jpg");
    }

    &_view_esk {
      background-image: url("../image/Tractor.jpg");
    }

    &_view_front-esk {
      background-image: url("../image/front-tractor.jpg");
    }

    &_view_scania {
      background-image: url("../image/scania.jpg");
    }
  }

  &__slogan {
    #default > .reset-indents();
    margin-top: auto;
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 1.2;
    color: @color-secondary;
    text-transform: uppercase;
  }
}
