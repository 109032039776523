.bids {
  padding-top: 60px;
  background-color: white;

  @media @desktop {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  &__slogan {
    #default > .reset-indents();
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.9px;

    @media @desktop {
      margin-bottom: 30px;
      font-size: 36px;
      line-height: 48px;
      font-weight: 600;
    }
  }

  &__sub-slogan {
    #default > .reset-indents();
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.5px;
    color: #000000;

    @media @desktop {
      margin-bottom: 60px;
      font-size: 20px;
      line-height: 28px;
    }

    &::after,
    &::before {
      content: "";
      display: block;
      width: 135px;
      height: 2px;
      background-color: @color-main;
    }
  }

  &__content {
    position: relative;
  }

  &__sub-slogan-text {
    margin-right: 15px;
    margin-left: 15px;

    @media @desktop {
      margin-right: 30px;
      margin-left: 30px;
    }
  }

  &__photos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    @media @tablet {
      margin-bottom: 60px;
    }
  }

  &__photo {
    width: 70px;
    height: 70px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    @media @tablet {
      width: 85px;
      height: 85px;
      margin-right: 45px;
    }

    &_view_big {
      width: 90px;
      height: 90px;

      @media @tablet {
        width: 110px;
        height: 110px;
      }
    }
  }

  &__image {
    width: 70px;
    height: 70px;
    object-fit: cover;

    @media @tablet {
      width: 85px;
      height: 85px;
    }
  }

  &__photo_view_big &__image {
    width: 90px;
    height: 90px;

    @media @tablet {
      width: 110px;
      height: 110px;
    }
  }

  &__text {
    margin-bottom: 40px;

    @media @tablet {
      margin-bottom: 75px;
    }

    @media @desktop {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    #default > .reset-indents();
    margin-bottom: 90px;
    font-size: 14px;
    font-weight: 500;
    color: black;
    letter-spacing: 0.4px;

    @media @tablet {
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 1.2;
    }

    @media @desktop {
      margin-bottom: 0;
      margin-right: 90px;
    }
  }

  &__buttons {
    display: flex;
    min-width: 200px;
    text-align: center;

    @media @tablet-only {
      margin-left: auto;
      margin-right: auto;
      max-width: 450px;
    }

    @media @desktop {
      display: block;
    }
  }

  &__button-action,
  &__button-all {
    width: ~"calc(50% - 15px)";

    @media @phone-only {
      padding: 5px;
    }

    @media @tablet {
      width: 100%;
    }
  }

  &__button-action {
    margin-right: 30px;

    @media @desktop {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__button-navigation {
    #default > .reset-indents();
    position: absolute;
    top: 50%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border: none;
    background-color: transparent;
    transform: translateY(-50%);

    @media @desktop {
      display: inline-flex;
    }

    &_next {
      right: -55px;
    }

    &_prev {
      left: -55px;
    }
  }

  &__button-navigation-icon {
    width: 30px;
    height: 25px;
    fill: @color-main;

    &_prev {
      transform: rotate(180deg);
    }
  }
}
