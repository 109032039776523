.useful {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 5;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  &::before {
    background-image: url("../image/useful-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  }

  &::after {
    background-color: rgba(255, 255, 255, 0.6);
  }

  &__container {
    margin-top: auto;
    margin-bottom: auto;
  }

  &__slogan {
    #default > .reset-indents();

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.5px;
    color: @color-text;

    @media @tablet {
      margin-bottom: 60px;
      font-size: 20px;
      line-height: 28px;
    }

    @media @desktop {
      margin-bottom: 90px;
    }

    &::after,
    &::before {
      content: "";
      display: block;
      width: 145px;
      height: 2px;
      background-color: @color-main;
    }
  }

  &__slogan-title {
    margin-right: 10px;
    margin-left: 10px;

    @media @tablet {
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  &__list {
    #default > .reset-indents();

    list-style: none;

    @media @tablet {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    @media @phone-only {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media @tablet {
      .column-mixin(2, 80px);
    }

    @media @desktop {
      .column-mixin(2, 30px);
    }
  }

  &__link {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px;
    border: 2px solid white;
    min-height: 190px;
    text-decoration: none;
    transition: background-color 0.15s linear;

    @media @tablet {
      box-sizing: content-box;
      padding: 15px;
      min-height: 250px;
    }

    @media @desktop {
      padding: 30px;
    }

    &:hover {
      background-color: white;
    }
  }

  &__icon {
    width: 95px;
    height: 50px;
    margin-bottom: 25px;
    fill: @color-main;

    @media @tablet {
      width: 130px;
      height: 70px;
      margin-bottom: 40px;
    }
  }

  &__title {
    #default > .reset-indents();

    font-size: 13px;
    line-height: 1.2;
    color: @color-text;
    text-transform: uppercase;
    transition: margin-bottom 0.15s linear, color 0.15s linear;

    @media @tablet {
      font-size: 18px;
    }
  }

  &__link:hover &__title {
    color: black;
    margin-bottom: 30px;
  }

  &__button {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear, opacity 0s linear;
  }

  &__link:hover &__button {
    position: static;
    opacity: 1;
    visibility: visible;
    transition: visibility 0.15s linear, opacity 0.15s linear;
  }
}
