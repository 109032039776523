.catalog {
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: @color-main_contrast;

  @media @tablet {
    padding-top: 100px;
    padding-bottom: 70px;
  }

  &__container {
    padding-left: 50px;
    padding-right: 50px;

    @media @desktop {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__slogan {
    #default > .reset-indents();

    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.1;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    text-align: center;
    color: black;

    @media @tablet {
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 1.2;
    }

    @media @desktop {
      font-size: 36px;
      line-height: 48px;
    }

    &_view_footer {
      margin-bottom: 10px;
      letter-spacing: 0;

      @media @desktop {
        margin-bottom: 30px;
      }
    }
  }

  &__sub-slogan {
    #default > .reset-indents();

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.5px;
    color: #000000;

    @media @tablet {
      margin-bottom: 45px;
      font-size: 18px;
    }

    @media @desktop {
      margin-bottom: 60px;
      font-size: 20px;
      line-height: 28px;
    }

    &::after,
    &::before {
      content: "";
      display: block;
      width: 135px;
      height: 2px;
      background-color: @color-main;
    }
  }

  &__sub-slogan-text {
    margin-right: 10px;
    margin-left: 10px;

    @media @tablet {
      margin-right: 20px;
      margin-left: 20px;
    }

    @media @desktop {
      margin-right: 30px;
      margin-left: 30px;
    }
  }

  &__list {
    margin-bottom: 20px;

    @media @tablet {
      margin-bottom: 70px;
    }

    &_view_inner_page {
      margin-bottom: 0;
    }
  }

  &__footer {
    text-align: center;

    .button {
      @media @phone-only {
        width: 50%;
      }
    }
  }
}
