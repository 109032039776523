.consultant {
  display: flex;

  @media @tablet {
    padding: 10px;
  }

  @media @desktop {
    padding: 15px 10px;
  }

  &__image-container,
  &__image {
    display: none;

    @media @tablet {
      display: block;
      width: 140px;
      height: 130px;
    }
  }

  &__image-container {
    margin-right: 15px;
    flex-shrink: 0;
  }

  &__image {
    object-fit: cover;
  }

  &__content {
    box-sizing: border-box;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__text {
    #default > .reset-indents();

    margin-bottom: 25px;
    font-size: 14px;
    line-height: 1.4;

    @media @tablet {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 1.2;
    }
  }
}
