.selectr {
  &-selected {
    padding: 13px 15px;
    background-color: transparent;
    border: 2px solid @color-main;
    border-radius: 0;

    &::before {
      border-color: #b8b8b8 transparent transparent;
      transition: border-color 0.15s linear;
    }

    &:hover::before {
      border-color: @color-main transparent transparent;
    }
  }

  &-label {
    font-size: 12px;
    line-height: 15px;
  }

  &-placeholder {
    font-size: 12px;
    line-height: 15px;
    color: @color-text;
    text-transform: uppercase;
  }

  &-options {
    background-color: black;
  }

  &-option {
    font-size: 12px;
    line-height: 15px;
    color: white;
  }

  &-option.active {
    background-color: white;
    color: black;
  }

  &-option.selected {
    background-color: @color-main;
  }
}
