@import "./normalize.less";
@import "./variables.less";
@import "./mixins.less";
@import "./fonts.less";

html {
  scroll-behavior: smooth;
}

body {
  font-family: @font-main;
  font-style: normal;
  color: @color-text;
  background-color: @color-main_contrast;
}
