.product-card {
  padding: 30px;
  background-color: @color-text_contrast;

  @media @tablet {
    padding: 50px 40px;
    min-height: 230px;
  }

  @media @desktop {
    padding: 50px 40px;
    padding-bottom: 90px;
    min-height: 335px;
  }

  &__slogan {
    #default > .reset-indents();
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 15px;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: @color-text;

    @media @desktop {
      margin-bottom: 50px;
      padding-bottom: 20px;
      font-size: 36px;
      line-height: 48px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 80px;
      height: 2px;
      background-color: @color-main;

      @media @desktop {
        width: 135px;
      }
    }
  }

  &__parameters {
    margin-bottom: 35px;

    @media @tablet {
      margin-bottom: 50px;
    }
  }

  &__list {
    #default > .reset-indents();
    list-style: none;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 1.2;

    @media @desktop {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 1.2;
    }

    &::before {
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      margin-right: 20px;
      border: 2px solid @color-main;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__price {
    font-size: 14px;

    @media @tablet {
      font-size: 16px;
    }
  }

  &__price-title {
    font-size: 16px;
    font-weight: 700;
    color: @color-main;

    @media @tablet {
      font-size: 18px;
    }
  }
}
